import { Color } from '@/design/system/helpers'

interface CategoryConfiguration {
  tone: Color
  projects: CategoryProjectConfiguration[]
}

interface CategoryProjectConfiguration {
  numberOfImages: number
}

interface ProjectCategoryTab {
  id: string
  title: string
  tone: Color
  tabPanelId: string
}

export interface ProjectCategoryTabPanel {
  id: string
  tone: Color
  tabId: string
  projects: ProjectCategoryTabPanelProject[]
}

export interface ProjectCategoryTabPanelProject {
  id: string
  title: string
  description: string
  items: ProjectCategoryTabPanelProjectItem[]
}

export interface ProjectCategoryTabPanelProjectItem {
  id: string
  title: string
  serviceGiven: string
  location: string
  imageSrc: string
  imageAlt: string
}

interface ProjectTabListConfig {
  tabs: ProjectCategoryTab[]
  tabPanels: ProjectCategoryTabPanel[]
  findTabPanelProject: (
    tabPanelId: string,
    projectId: string
  ) => FindTabPanelProjectResult
}

interface FindTabPanelProjectResult {
  tabPanel: ProjectCategoryTabPanel | undefined
  project: ProjectCategoryTabPanelProject | undefined
}

export function getProjectsTabListConfig(): ProjectTabListConfig {
  const categoryRecords: Record<string, CategoryConfiguration> = {
    agroindustrial: {
      tone: 'secondary',
      projects: [
        {
          numberOfImages: 2,
        },
        {
          numberOfImages: 2,
        },
        {
          numberOfImages: 1,
        },
        {
          numberOfImages: 2,
        },
        {
          numberOfImages: 2,
        },
        {
          numberOfImages: 2,
        },
        {
          numberOfImages: 2,
        },
        {
          numberOfImages: 3,
        },
      ],
    },
    industrial: {
      tone: 'quaternary',
      projects: [
        {
          numberOfImages: 2,
        },
        {
          numberOfImages: 3,
        },
        {
          numberOfImages: 2,
        },
      ],
    },
    comercial: {
      tone: 'primary',
      projects: [
        {
          numberOfImages: 2,
        },
      ],
    },
    residential: {
      tone: 'tertiary',
      projects: [
        {
          numberOfImages: 2,
        },
        {
          numberOfImages: 2,
        },
      ],
    },
  }

  const tabs: ProjectCategoryTab[] = []
  const tabPanels: ProjectCategoryTabPanel[] = []

  const categoryValues = Object.entries(categoryRecords)

  for (const [categoryName, { tone, projects }] of categoryValues) {
    const tabPanelProjects: ProjectCategoryTabPanelProject[] = []

    for (let projectIndex = 0; projectIndex < projects.length; projectIndex++) {
      const categoryProject = projects[projectIndex]
      const tabPanelProjectItem: ProjectCategoryTabPanelProjectItem[] = []
      const projectBaseI18nPath = `projects.${categoryName}.projects.${projectIndex}`

      for (
        let imageIndex = 0;
        imageIndex < categoryProject.numberOfImages;
        imageIndex++
      ) {
        const imageBaseI18nPath = `${projectBaseI18nPath}.images.${imageIndex}`

        tabPanelProjectItem.push({
          id: `${categoryName}-${projectIndex}-${imageIndex}`,
          title: `${imageBaseI18nPath}.title`,
          serviceGiven: `${imageBaseI18nPath}.serviceGiven`,
          location: `${imageBaseI18nPath}.location`,
          imageSrc: `@/assets/images/projects/${categoryName}/${projectIndex}/${imageIndex}.jpg`,
          imageAlt: `${imageBaseI18nPath}.imageAlt`,
        })
      }

      tabPanelProjects.push({
        id: `${categoryName}-${projectIndex}`,
        title: `${projectBaseI18nPath}.title`,
        description: `${projectBaseI18nPath}.description`,
        items: tabPanelProjectItem,
      })
    }

    const tabId = `${categoryName}-tab`
    const tabPanelId = `${categoryName}-tabpanel`

    tabs.push({
      id: tabId,
      tone,
      title: `projects.${categoryName}.title`,
      tabPanelId: tabPanelId,
    })

    tabPanels.push({
      id: tabPanelId,
      tone,
      tabId: tabId,
      projects: tabPanelProjects,
    })
  }

  function findTabPanelProject(
    tabPanelId: string,
    projectId: string
  ): FindTabPanelProjectResult {
    const tabPanel = tabPanels.find((tabPanel) => tabPanel.id === tabPanelId)
    const project =
      (tabPanel && tabPanel.projects.find((item) => item.id === projectId)) ||
      undefined

    return {
      tabPanel,
      project,
    }
  }

  return {
    tabs,
    tabPanels,
    findTabPanelProject,
  }
}

export const tabListToneClassesConfig: Record<Color, string[]> = {
  primary: [
    'bg-primary-900',
    'text-primary-50',
    'dark:bg-primary-700',
    'dark:text-primary-100',
  ],
  secondary: [
    'bg-secondary-900',
    'text-secondary-50',
    'dark:bg-secondary-700',
    'dark:text-secondary-100',
  ],
  tertiary: [
    'bg-tertiary-900',
    'text-tertiary-50',
    'dark:bg-tertiary-700',
    'dark:text-tertiary-100',
  ],
  quaternary: [
    'bg-quaternary-900',
    'text-quaternary-50',
    'dark:bg-quaternary-700',
    'dark:text-quaternary-100',
  ],
  critical: [],
  caution: [],
  positive: [],
  neutral: [],
  info: [],
  promote: [],
}
