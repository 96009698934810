


























































































import { useWindowKeyboardListener } from '@/composition/useWindowKeyboardListener'
import LandingSection from '@/components/LandingSection.vue'
import CurveWaterWaves from '@/components/CurveWaterWaves.vue'
import ProjectTab from '@/components/ProjectTab.vue'
import ProjectTabPanel from '@/components/ProjectTabPanel.vue'
import ProjectTabPanelCarousel from '@/components/ProjectTabPanelCarousel.vue'
import ProjectModal from '@/components/ProjectModal.vue'
import {
  getProjectsTabListConfig,
  ProjectCategoryTabPanel,
  ProjectCategoryTabPanelProject,
} from '@/utils/projects'
import { defineComponent, ref } from '@vue/composition-api'

export default defineComponent({
  name: 'LandingSectionProjects',

  components: {
    LandingSection,
    CurveWaterWaves,
    ProjectTab,
    ProjectTabPanel,
    ProjectTabPanelCarousel,
    ProjectModal,
  },

  setup() {
    const { tabs, tabPanels, findTabPanelProject } = getProjectsTabListConfig()

    const selectedTabId = ref(tabs[0].id)
    const selectedTabPanel = ref<ProjectCategoryTabPanel | null>(null)
    const selectedTabPanelProject = ref<ProjectCategoryTabPanelProject | null>(
      null
    )

    function openModal(tabPanelId: string, projectId: string) {
      const { tabPanel, project } = findTabPanelProject(tabPanelId, projectId)
      if (!tabPanel || !project) return

      selectedTabPanel.value = tabPanel
      selectedTabPanelProject.value = project
    }

    function closeModal(): void {
      if (!selectedTabPanel.value && !selectedTabPanelProject.value) return

      selectedTabPanel.value = null
      selectedTabPanelProject.value = null
    }

    const selectPreviousTab = () => {
      const selectedTabIndex = tabs.findIndex(
        (tab) => tab.id === selectedTabId.value
      )

      if (selectedTabIndex === -1) return

      selectedTabId.value =
        tabs[(selectedTabIndex === 0 ? tabs.length : selectedTabIndex) - 1].id
    }

    const selectNextTab = () => {
      const selectedTabIndex = tabs.findIndex(
        (tab) => tab.id === selectedTabId.value
      )

      if (selectedTabIndex === -1) return

      selectedTabId.value =
        tabs[selectedTabIndex === tabs.length - 1 ? 0 : selectedTabIndex + 1].id
    }

    const onTabKeyboardEvent = (event: KeyboardEvent) => {
      const key = event.key
      if (key === 'ArrowLeft') {
        selectPreviousTab()
      } else if (key === 'ArrowRight') {
        selectNextTab()
      }
    }

    useWindowKeyboardListener({
      key: 'Escape',
      handler: closeModal,
    })

    return {
      tabs,
      tabPanels,
      selectedTabId,
      openModal,
      closeModal,
      selectedTabPanel,
      selectedTabPanelProject,
      onTabKeyboardEvent,
    }
  },
})
