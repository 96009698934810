












import { Color } from '@/design/system/helpers'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { tabListToneClassesConfig } from '@/utils/projects'

export default defineComponent({
  name: 'ProjectTabPanel',

  props: {
    id: {
      type: String,
      required: true,
    },

    tabId: {
      type: String,
      required: true,
    },

    tone: {
      type: String as PropType<Color>,
      required: true,
    },

    isHidden: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const computedClasses = computed(() => [
      'rounded-b-xl',
      ...(!props.isHidden ? ['dark:bg-opacity-40'] : []),
      ...(!props.isHidden
        ? tabListToneClassesConfig[props.tone]
        : ['bg-gray-50 text-gray-600 dark:bg-opacity-10']),
    ])

    return {
      computedClasses,
    }
  },
})
