



























































import i18n from '@/setup/i18n'
import { Color } from '@/design/system/helpers'
import {
  ProjectCategoryTabPanelProject,
  tabListToneClassesConfig,
} from '@/utils/projects'
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from '@vue/composition-api'
import PinchZoom from 'vue-pinch-zoom'
import { Swiper } from 'vue-awesome-swiper'

export default defineComponent({
  name: 'ProjectModal',

  components: {
    PinchZoom,
  },

  props: {
    project: {
      type: Object as PropType<ProjectCategoryTabPanelProject>,
      required: true,
    },

    tone: {
      type: String as PropType<Color>,
      required: true,
    },
  },

  emits: {
    close: null,
  },

  setup(props, context) {
    const pinchZooms = ref<InstanceType<typeof PinchZoom>[] | null>(null)

    const isAnyPinchZoomComponentZoomedIn = computed(() =>
      Boolean(
        pinchZooms.value && pinchZooms.value.some((item) => item.isZoomedIn)
      )
    )

    const swiper = ref<InstanceType<typeof Swiper> | null>(null)

    const swiperOptions = computed(() => ({
      keyboard: {
        enabled: true,
      },
      mousewheel: false,
      allowTouchMove: !isAnyPinchZoomComponentZoomedIn.value,
      spaceBetween: 30,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    }))

    watch(
      swiperOptions,
      (newValue) => {
        if (!swiper.value) return
        swiper.value.$swiper.allowTouchMove = newValue.allowTouchMove
      },
      {
        deep: true,
      }
    )

    const toneClasses = computed(() => tabListToneClassesConfig[props.tone])

    const ariaLabelledBy = computed(() => i18n.t(props.project.title))
    const ariaDescribedBy = computed(() => i18n.t(props.project.description))

    function getImageAlt(path: string) {
      return i18n.t(path, { m3: 'm3' }) as string
    }

    function close(): void {
      context.emit('close')
    }

    return {
      swiper,
      swiperOptions,
      ariaLabelledBy,
      ariaDescribedBy,
      getImageAlt,
      toneClasses,
      close,
      pinchZooms,
    }
  },
})
