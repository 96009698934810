
























import ProjectTabPanelSlideItem from '@/components/ProjectTabPanelSlideItem.vue'
import { ProjectCategoryTabPanelProject } from '@/utils/projects'
import { defineComponent, PropType, reactive } from '@vue/composition-api'

export default defineComponent({
  name: 'ProjectTabPanelCarousel',

  components: {
    ProjectTabPanelSlideItem,
  },

  props: {
    projects: {
      type: Array as PropType<ProjectCategoryTabPanelProject[]>,
      required: true,
    },
  },

  emits: {
    select: (value: unknown) => typeof value === 'string',
  },

  setup(props, context) {
    const swiperOptions = reactive({
      slidesPerView: 'auto',
      spaceBetween: 40,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    })

    function selectProject(projectId: string): void {
      context.emit('select', projectId)
    }

    return {
      swiperOptions,
      selectProject,
    }
  },
})
