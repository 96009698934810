































































import i18n from '@/setup/i18n'
import { computed, defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ProjectTabPanelSlideItem',

  props: {
    title: {
      type: String,
      required: true,
    },

    serviceGiven: {
      type: String,
      required: true,
    },

    location: {
      type: String,
      required: true,
    },

    imageSrc: {
      type: String,
      required: true,
    },

    imageAlt: {
      type: String,
      required: true,
    },
  },

  emits: {
    open: null,
  },

  setup(props, context) {
    const computedImageAlt = computed(
      () => i18n.t(props.imageAlt, { m3: 'm3' }) as string
    )

    function open(): void {
      context.emit('open')
    }

    return {
      computedImageAlt,
      open,
    }
  },
})
