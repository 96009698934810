














import { Color } from '@/design/system/helpers'
import { computed, defineComponent, PropType } from '@vue/composition-api'
import { tabListToneClassesConfig } from '@/utils/projects'

export default defineComponent({
  name: 'ProjectTab',

  props: {
    id: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    tone: {
      type: String as PropType<Color>,
      required: true,
    },

    isSelected: {
      type: Boolean,
      default: false,
    },

    tabPanelId: {
      type: String,
      required: true,
    },
  },

  emits: ['select'],

  setup(props, context) {
    const computedClasses = computed(() => [
      'cursor-pointer text-center p-4 md:rounded-t-xl font-bold',
      'focus:outline-none',
      'focus-visible:ring-2',
      'focus-visible:ring-primary-300',
      'dark:focus-visible:ring-gray-200',
      ...(props.isSelected ? ['dark:bg-opacity-40'] : []),
      ...(props.isSelected
        ? tabListToneClassesConfig[props.tone]
        : [
            'bg-gray-50 text-gray-600 dark:text-gray-400 dark:bg-gray-500 dark:bg-opacity-10',
          ]),
    ])

    function onClick() {
      context.emit('select', props.id)
    }

    return {
      computedClasses,
      onClick,
    }
  },
})
